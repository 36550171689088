<template>
  <v-app>
    <v-content class="fullBg">
      <v-container class="fill-height" fluid>
        <a class="navbar-brand" href="/home.php"
          ><img
            src="/assets/images/FinSupport_logo.png"
            alt=""
            class="img-fluid"
        /></a>
        <v-row align="top" justify="center">
          <v-col cols="12" sm="8" md="4">
            <v-alert v-if="message" tile class="mt-5" type="success">{{
              message
            }}</v-alert>
            <v-alert v-if="errorMessage" tile class="mt-5" type="error">{{
              errorMessage
            }}</v-alert>
          </v-col>
        </v-row>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      errorMessage: null,
      message: null,
      sending: false,
      valid: false,
    };
  },
  created() {
    this.confirmEmail();
  },
  methods: {
    confirmEmail() {
      this.errorMessage = null;
      this.post(
        "/activate/" + this.$route.params.id + "/" + this.$route.params.hash
      )
        .then((data) => {
          this.message = this.$t(data.message);
          setTimeout(() => {
            this.$router.push("/");
          }, 2000);
        })
        .catch((error) => {
          this.errorMessage = this.$t(error.message);
        });
    },
  },
};
</script>

<style scoped>
.fullBg {
  background-color: rgb(21, 21, 24);
  background-image: url("~@/assets/login.png");
  background-size: contain;
  background-position: 100% 50%;
  background-repeat: no-repeat;
}
.img-fluid {
  max-height: 100px;
  width: 320px;
  float: left;
  top: 20px;
  position: absolute;
}
</style>
